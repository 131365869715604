import { gql } from '@apollo/client'

const USER_ACHIEVMENTS_COLLECTED = gql`
  query UserAchievementsCollected {
    account {
      userInfo {
        ownerUID
        bigwinWitnessCount
        rewardedTaskCount
        mineCount
        achievementsProgress {
          title
          subtitle
          value
          milestone
          imageUrl
        }
        achievementsCollected {
          id
          achievement
          info {
            id
            achievement
            type
            rank
            group
            title
            description
            image
            icon
            imageUrl
            iconUrl
          }
          date
        }
      }
    }
  }
`

export { USER_ACHIEVMENTS_COLLECTED }
