import { Box } from '@mui/material';
import { CSSProperties } from 'react';

export interface BadgeRevealProps {
  src: string;
  progress: number;
}

export const BadgeReveal = ({ src, progress }: BadgeRevealProps) => {
  const angle = (progress / 100) * 360;
  return (
    <Box
      sx={{
        width: 100,
        height: 100,
        borderRadius: "50%",
        overflow: "hidden",
        position: "relative",
        mb: 2,
      }}
    >
      <img src={src} width="100%" height="100%" />
      <Box
        style={
          {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.92)",
            "--angle": angle + "deg",
            maskImage:
              "conic-gradient(from 0deg, transparent var(--angle), black var(--angle))",
            "-webkit-mask-image":
              "conic-gradient(from 0deg, transparent var(--angle), black var(--angle))",
          } as CSSProperties
        }
      />
    </Box>
  );
};
