import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import { BadgeReveal } from './BadgeReveal';

interface BadgeProgressTimelineProps {
  title: string;
  subtitle: string;
  value: number;
  milestone: number;
  imageUrl: string;
}

const TaskerProgressTimeline: React.FC<BadgeProgressTimelineProps> = ({ title, subtitle, value, milestone, imageUrl }) => {
  const progress = Math.min(100, (value / milestone) * 100);
  return (
    <Card>
      <Box p={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <BadgeReveal progress={progress} src={imageUrl} />
          <Typography variant="h6"  align="center">
            {title}
          </Typography>
          <Typography variant="body1" align="center" sx={{ color: 'text.secondary' }}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default TaskerProgressTimeline;