import { LearnAndEarnSelectedOfferProvider, LearnAndEarnOffer, useLearnAndEarnOffers, LearnAndEarnSelectedOfferDialog } from "../components/LearnAndEarn";
import CustomBasePage from "../CustomBasePage";
import { ThemeProvider, Box, CssBaseline } from "@mui/material";
import { lightTheme } from "../themes";

const LearnAndEarn = () => {
  const { offers, isLoading } = useLearnAndEarnOffers();

  return (
    <Box sx={{ maxWidth: 510, width: '100%', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column', gap: 2 }}>
      {offers.map((offer, i) => (
        <LearnAndEarnOffer key={i} offer={offer} loading={isLoading} />
      ))}
    </Box>
  );
};

export default () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <CustomBasePage footer={false} mt={2} mb={2}>
        <LearnAndEarnSelectedOfferProvider>
          <LearnAndEarn />
          <LearnAndEarnSelectedOfferDialog />
        </LearnAndEarnSelectedOfferProvider>
      </CustomBasePage>
    </ThemeProvider>
  );
};
