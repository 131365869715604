import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState
} from "react";
import { ILearnAndEarnOffer } from "./types";

interface ILearnAndEarnSelectedOfferContext {
  open: string;
  setOpen: Dispatch<SetStateAction<string>>;
  selected: ILearnAndEarnOffer | null;
  setSelected: Dispatch<SetStateAction<ILearnAndEarnOffer | null>>;
}

const LearnAndEarnSelectedOfferContext =
  createContext<null | ILearnAndEarnSelectedOfferContext>(null);

export const LearnAndEarnSelectedOfferProvider = ({
  children,
}: PropsWithChildren) => {
  const [open, setOpen] = useState<string>("");
  const [selected, setSelected] = useState<ILearnAndEarnOffer | null>(null);
  return (
    <LearnAndEarnSelectedOfferContext.Provider
      value={{ open, setOpen, selected, setSelected }}
    >
      {children}
    </LearnAndEarnSelectedOfferContext.Provider>
  );
};

export const useLearnAndEarnSelectedOffer = () => {
  const context = useContext(LearnAndEarnSelectedOfferContext);

  if (!context)
    throw new Error(
      "useLearnAndEarnSelectedOffer must be used within a LearnAndEarnSelectedOfferProvider"
    );

  return context;
};

