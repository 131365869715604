const scripts = new Map<string, Promise<unknown>>()

export function loadScript(src: string) {
  const cached = scripts.get(src)
  if (cached) return cached

  const promise = new Promise((res, rej) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = res
    script.onerror = rej
    document.head.appendChild(script)
  })

  scripts.set(src, promise)
  return promise
}
