import { getAuth } from 'firebase/auth'
import { ParsedUrlQueryInput } from 'querystring'
import React, { FC, ReactElement, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { fromSearch, toSearch } from '../../../utils'
import { useUser, useUserInfo } from '../../hooks'
import { RedirectWithQuery } from '../RedirectWithQuery'
import { useAsyncEffect } from '../../../utils/useAsyncEffect'
import { SplashScreenLogo } from '../../../components'

export const ExternalRedirect = ({ to }: { to: string }) => {
  window.location.href = to
  return null
}

export const Private = ({ children, component, to }: React.PropsWithChildren<{ to: string; component?: () => ReactElement }>) => {
  const { pathname, search } = useLocation()
  const user = getAuth().currentUser
  const { token, email, ...params } = fromSearch(search)
  if (token)
    return (
      <TokenMatch
        token={`${token}`}
        redirect={`${pathname}?${toSearch(params)}`}
        params={{ ...params, redirect: pathname }}
      />
    )
  if (user && email && user.email !== email) {
    return <LogoutMatch to={to} params={{ ...params, email, redirect: pathname }} />
  }
  if (user) return component ? component() : <>{children}</>
  if (email) return <RedirectWithQuery to={`${to}?${toSearch({ ...params, email, redirect: pathname })}`} />
  return <RedirectWithQuery to={`${to}?${toSearch({ ...params, redirect: pathname })}`} />
}

const globalState = { hadToken: false }

// THIS ROUTE ONLY ALLOWS THE USER TO ACCESS THE PAGE IF THEY USED A TOKEN
export const PrivateToken = ({ children, component, to }: React.PropsWithChildren<{ to: string; component?: () => ReactElement }>) => {
  const { pathname, search } = useLocation()
  const user = getAuth().currentUser
  const { token, email, ...params } = fromSearch(search)


  if (token) {
    globalState.hadToken = true
  }
  
  if (token)
    return (
      <TokenMatch
        token={`${token}`}
        redirect={`${pathname}?${toSearch(params)}`}
        params={{ ...params, redirect: pathname }}
      />
    )
  if (user && email && user.email !== email) {
    return <LogoutMatch to={to} params={{ ...params, email, redirect: pathname }} />
  }
  if (user && globalState.hadToken) return component ? component() : <>{children}</>
  if (email) return <RedirectWithQuery to={`${to}?${toSearch({ ...params, email, redirect: pathname })}`} />
  return <RedirectWithQuery to={`${to}?${toSearch({ ...params, redirect: pathname })}`} />
}

export const PrivateEmail = ({ children, to }: React.PropsWithChildren<{ to: string; }>) => {
  const { pathname, search } = useLocation()
  const user = getAuth().currentUser
  const { token, email, ...params } = fromSearch(search)
  if (token)
    return (
      <TokenMatch
        token={`${token}`}
        redirect={`${pathname}?${toSearch(params)}`}
        params={{ ...params, redirect: pathname }}
      />
    )
  if (user && email && user.email !== email)
    return <LogoutMatch to={to} params={{ ...params, email, redirect: pathname }} />
  return <>{children}</>
}

export const RequiredEmail = ({ oneOf, children }: React.PropsWithChildren<{ oneOf: string[] }>) => {
  const { user } = useUser()
  const email = user?.email
  const pass = useMemo(() => {
    if (!email) return false
    return oneOf.indexOf(email) !== -1
  }, [oneOf, email])
  if (pass) return <>{children}</>
  return null
}

export const RequireReauthentication = ({ children, to }: React.PropsWithChildren<{ to: string }>) => {
  const { pathname, search } = useLocation()

  const user = getAuth().currentUser
  const { email, ...params } = fromSearch(search)
  if (email) return <LogoutMatch to={to} params={{ ...params, email, redirect: pathname }} />
  if (user) return <>{children}</>
  return <RedirectWithQuery to={`${to}?${toSearch({ ...params, redirect: pathname })}`} />
}

interface InvitedAdminRouteProps {
  property: string
}

export const InvitedAdminRoute = ({ children, property }: React.PropsWithChildren<InvitedAdminRouteProps>) => {
  const { initialized, user } = useUser()
  const { userInfo } = useUserInfo()
  const { pathname } = useLocation()
  if (initialized && !user) {
    return <RedirectWithQuery to={`/auth?redirect=${pathname}`} />
  }
  if (userInfo !== null && (!userInfo || !(userInfo as Record<string, any>)[property])) {
    return <RedirectWithQuery to="/" />
  }
  return <>{children}</>
}

const LogoutMatch = ({ to, params }: { to: string; params?: ParsedUrlQueryInput }) => {
  const { logout } = useUser()
  useEffect(() => {
    logout?.(`${to}?${toSearch(params)}`)
  }, [logout, params, to])
  return (
    <div className="text-center text-danger">
      <div className="spinner-border" />
    </div>
  )
}

const TokenMatch = ({ token, redirect, params }: { token: string; redirect: string; params?: ParsedUrlQueryInput }) => {
  const { loginWithToken, tokenLogin } = useUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    await loginWithToken?.(token, redirect, toSearch(params))
  }, [loginWithToken, params, redirect, token])
  return tokenLogin ? <SplashScreenLogo /> : null
}

export default Private
