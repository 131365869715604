import { ThemeOptions, createTheme } from '@mui/material/styles'
import merge from 'lodash/merge'

const primaryColor = '#ee7268'
const secondaryColor = '#34C588'

const primary = ['Titillium Web', 'Helvetica', 'sans-serif'].join(',')
const title = ['Titillium Web', 'Helvetica', 'sans-serif'].join(',')

export const fontFamily = {
  primary,
  title,
}

export const themeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          '&:hover': {
            filter: 'brightness(75%)',
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#262525'
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: fontFamily.primary,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    h1: {
      fontSize: '4rem',
    },
    h2: {
      fontSize: '2.4rem',
    },
    h3: {
      fontSize: '2.24rem',
    },
    h4: {
      fontSize: '2rem',
    },
    h5: {
      fontSize: '1.5rem',
    },
    h6: {
      fontSize: '1.25rem',
    },
    subtitle1: {
      opacity: '50%',
      textTransform: 'uppercase',
    },
    subtitle2: {
      opacity: '50%',
    },
  },
}

const options: ThemeOptions = {
  palette: {
    info: {
      main: '#4a90e3',
    },
  },
}

const lightOptions: ThemeOptions = { palette: {mode: 'light', background: { default: 'rgb(255,255,255)', paper: 'rgb(250,250,250)' }} }

export const theme = createTheme(merge(options, themeOptions))
export const lightTheme = createTheme(merge(options, themeOptions, lightOptions))