import { MouseEventHandler } from 'react'
import { Route, Routes } from 'react-router-dom'

import { LoginNavbar, Navbar } from '../../components'
import { useUser } from '../../User'

interface Props {
  toggle: MouseEventHandler<HTMLButtonElement>
}

const NavbarRouter: React.FC<React.PropsWithChildren<Props>> = ({ toggle }) => {
  const { user } = useUser()
  return (
    <Routes>
      <Route
        path="/learn"
        element={null}
      />
      <Route
        path="/sweepstakes"
        element={user ? <Navbar toggleDrawer={toggle} /> : <LoginNavbar redirect="/sweepstakes" />}
      />
      <Route path="/redeem" element={user ? <Navbar toggleDrawer={toggle} /> : <LoginNavbar redirect="/redeem" />} />
      <Route
        path="/redeem-details/*"
        element={user ? <Navbar toggleDrawer={toggle} /> : <LoginNavbar redirect="/redeem" />}
      />
      <Route
        path="/account-email/verify"
        element={user ? <Navbar toggleDrawer={toggle} /> : <LoginNavbar redirect="/redeem" />}
      />
      <Route path="/redeem/*" element={user ? <Navbar toggleDrawer={toggle} /> : <LoginNavbar redirect="/redeem" />} />
      <Route path="/" element={<Navbar toggleDrawer={toggle} />} />
      <Route path="*" element={<Navbar toggleDrawer={toggle} />} />
    </Routes>
  )
}

export default NavbarRouter
