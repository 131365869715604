import { WarningOutlined } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import CustomBasePage from "../CustomBasePage";

const OfferLimitReached = () => {
  return (
    <Paper 
      elevation={0}
      sx={{
        p: 3,
        maxWidth: 400,
        mx: 'auto',
        mt: 4,
        backgroundColor: 'rgba(255, 245, 238, 0.1)',
        border: '1px solid rgba(255, 165, 0, 0.3)',
        borderRadius: '8px',
        color: '#FFA500'
      }}
    >
      <Box display="flex" alignItems="center" mb={1}>
        <WarningOutlined color="warning" sx={{ mr: 1, fontSize: 20 }} />
        <Typography variant="h6" component="h2" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
          Offer Limit Reached
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ color: 'rgba(255, 245, 238, 0.8)', pl: '28px' }}>
        This offer has hit its limit for the day. Please check back tomorrow for new opportunities.
      </Typography>
    </Paper>
  );
};

export default () => {
  return (
    <CustomBasePage sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <OfferLimitReached />
    </CustomBasePage>
  );
};
