import { loadScript } from "../../utils/script"

const max = { height: 1024, width: 1024 }
export function cropImage(imageFile: File): Promise<string> {
  return new Promise((res) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      const img = document.createElement('img')
      img.onload = function () {
        const ctx = canvas.getContext('2d')
        if (ctx) {
          const scaleToFitX = canvas.width / img.width
          const scaleToFitY = canvas.height / img.height
          const scaleFactor = Math.max(scaleToFitX, scaleToFitY)

          // Calculate the new dimensions for the image
          const scaledWidth = img.width * scaleFactor
          const scaledHeight = img.height * scaleFactor

          // Calculate the position to center the image on the canvas
          const offsetX = (canvas.width - scaledWidth) / 2
          const offsetY = (canvas.height - scaledHeight) / 2

          // Draw the image onto the canvas
          ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight)
          const dataurl = canvas.toDataURL(imageFile.type)
          res(dataurl)
        } else {
          res('')
        }
      }
      if (e.target) {
        img.src = e.target.result as string
      } else {
        res('')
      }
    }
    reader.readAsDataURL(imageFile)
    const canvas = document.createElement('canvas')
    canvas.height = max.height
    canvas.width = max.width
  })
}

export async function convertHEIC(file: File) {
  if (!isHEIC(file)) return file

  await loadScript('https://cdn.jsdelivr.net/npm/heic2any@0.0.3/dist/heic2any.min.js')
  const heic2any = (window as any)['heic2any']
  return heic2any({
    blob: file,
    toType: 'image/jpg',
  })
}

function isHEIC(file: File) {
  // check file extension since windows returns blank mime for heic
  const x = file.type ? file.type.split('image/').pop() : (file.name.split('.').pop() || '').toLowerCase()
  return x == 'heic' || x == 'heif'
}