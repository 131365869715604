import { useQuery } from '@apollo/react-hooks'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select'
import TablePagination from '@mui/material/TablePagination'
import get from 'lodash/get'
import times from 'lodash/times'
import qs from 'querystring'
import { ChangeEventHandler, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { CustomList, CustomListHeader, TablePaginationActions, useScrollContext } from '../../../components'
import { FlexCol, FlexRow } from '../../../components/Flex'
import { ErrorViewer } from '../../../componentsPure'
import { DROPS, DROPS_HISTORY } from './gql'
import DropQuadkey from './Item'
import NoGeoDrops from './NoItems'

const FilterMenu: React.FC<React.PropsWithChildren<SelectProps<string>>> = (props) => {
  return (
    <Select variant="standard" {...props}>
      {/*<MenuItem value={'all'}>All</MenuItem>*/}
      <MenuItem value={'dropsCollected'}>Collected</MenuItem>
      <MenuItem value={'dropsCreated'}>Dropped</MenuItem>
      <MenuItem value={'geoclaimRewards'}>Geoclaim Rewards</MenuItem>
      <MenuItem value={'referrerRewards'}>Referral Rewards</MenuItem>
      <MenuItem value={'bonusRewards'}>Bonus Rewards</MenuItem>
      <MenuItem value={'surveyRewards'}>Survey Rewards</MenuItem>
      {/* <MenuItem value={'inmarketRewards'}>Inmarket Rewards</MenuItem> */}
      <MenuItem value={'verificationRewards'}>Verification Rewards</MenuItem>
      <MenuItem value={'specialOffers'}>Special Offers</MenuItem>
      <MenuItem value={'learnAndEarn'}>Learn and Earn</MenuItem>
      <MenuItem value={'appInstallRewards'}>Play Rewards</MenuItem>
      <MenuItem value={'businessEntryRewards'}>Business Entry Rewards</MenuItem>
      <MenuItem value={'premiumTaskBonus'}>Rewarded Task Boost</MenuItem>
      <MenuItem value={'driving'}>Driving Analysis Rewards</MenuItem>
      <MenuItem value={'sentinelSharing'}>SentinelX Sharing</MenuItem>
    </Select>
  )
}

interface DropsHistory {
  id: string
}

interface DropsResult {
  drops: {
    data: DropsHistory[]
    total: number
  }
}

interface DropsHistoryResult {
  dropsHistory: {
    data: DropsHistory[]
    total: number
  }
}

const GeodropsList = () => {
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { state, search } = useLocation()
  // eslint-disable-next-line deprecation/deprecation
  const { tab: tabQ } = qs.parse(search.replace('?', ''))
  const [tab, setTab] = useState(tabQ ?? 'history')
  const [filter, setFilter] = useState((state as { filter?: string })?.filter ?? 'dropsCollected')
  const variables = useMemo(
    () => ({
      field: filter === 'dropsCollected' ? 'claimedTime' : 'dropTime',
      filter,
      page,
      perPage,
    }),
    [filter, perPage, page],
  )
  const dropsHistory = useQuery<DropsHistoryResult>(DROPS_HISTORY, { skip: tab !== 'history', variables })
  const drops = useQuery<DropsResult>(DROPS, { skip: tab === 'history', variables })
  const { data, loading, error } = tab === 'history' ? dropsHistory : drops

  const remoteRowCount = get(data, 'drops.total') || get(data, 'dropsHistory.total')

  const changePage = (p: number) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (_event: unknown, newPage: number) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const handleChange = (ev: SelectChangeEvent<string>) => {
    setFilter(ev.target.value)
    changePage(1)
  }
  const list = tab === 'history' ? dropsHistory?.data?.dropsHistory?.data ?? [] : drops?.data?.drops?.data ?? []

  return (
    <CustomList
      header={
        <CustomListHeader sx={{ alignItems: 'stretch', flexDirection: 'column' }} title="Geodrops">
          <FlexRow>
            <FilterMenu variant="standard" value={filter} onChange={handleChange} />
            <Box ml={1} flexGrow={1} />
            <FlexRow>
              <Button
                size="small"
                onClick={() => setTab('recent')}
                color={tab !== 'history' ? 'primary' : 'inherit'}
                variant={tab !== 'history' ? 'contained' : 'text'}
              >
                Recent
              </Button>
              <Button
                size="small"
                onClick={() => setTab('history')}
                color={tab === 'history' ? 'primary' : 'inherit'}
                variant={tab === 'history' ? 'contained' : 'text'}
              >
                History
              </Button>
            </FlexRow>
          </FlexRow>
        </CustomListHeader>
      }
    >
      <FlexCol alignItems="stretch">
        {loading ? (
          times(perPage).map((a, i) => (
            <FlexCol key={i} alignItems="stretch">
              <DropQuadkey loading={true} />
              <Divider />
            </FlexCol>
          ))
        ) : list.length ? (
          list.map((item) => (
            <FlexCol key={item.id} alignItems="stretch">
              <DropQuadkey {...item} />
              <Divider />
            </FlexCol>
          ))
        ) : error ? (
          <ErrorViewer error={error} />
        ) : (
          <NoGeoDrops />
        )}
        {remoteRowCount ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={remoteRowCount}
            rowsPerPage={variables.perPage}
            page={variables.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          ></TablePagination>
        ) : null}
      </FlexCol>
    </CustomList>
  )
}

export default GeodropsList
