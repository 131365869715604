import React, { ChangeEvent, UIEventHandler, useCallback, useEffect, useId, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
  Box,
  Pagination,
  TablePagination,
} from "@mui/material";
import { useLearnAndEarnSubmit, ILearnAndEarnOfferSubmitResult } from "./hooks";
import { useLearnAndEarnSelectedOffer } from "./Selected";
import { ILearnAndEarnOffer } from "./types";
import { SlideInUp } from "../Animate";
import { numberFormat } from "../../utils";
import { loadScript } from "../../utils/script";
import type { PDFDocumentProxy, default as PdfJsLib } from 'pdfjs-dist'
import "pdfjs-dist/build/pdf.worker.mjs"

const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

export const LearnAndEarnSelectedOfferDialog = () => {
  const { open, setOpen, selected } = useLearnAndEarnSelectedOffer();
  const { isMutating, handleSubmit } = useLearnAndEarnSubmit();

  const [answers, setAnswers] = useState<Record<string, number>>({});
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [submissionResult, setSubmissionResult] =
    useState<ILearnAndEarnOfferSubmitResult | null>(null);

  const handleAnswerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAnswers({ ...answers, [currentQuestion]: Number(event.target.value) });
  };

  const handleBack = () => {
    if (currentQuestion >= 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else {
      setOpen("");
    }
  };

  const handleNext = async () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else if (selected) {
      // const grecaptcha = (window as any).grecaptcha
      // grecaptcha.ready(async () => {
        // const recaptchaToken = await grecaptcha.execute(siteKey, {action: 'submit'})
        const result = await handleSubmit({ 
          _id: selected._id, 
          answers, 
          // recaptchaToken 
        });
        if (result) {
          setSubmissionResult(result);
          setShowConfirmation(true);
        }
      // });
    }
  };

  const handleClose = () => {
    setOpen("");
    setAnswers({});
    setCurrentQuestion(-1);
    setScrollProgress(0);
    setScrolledToBottom(false);
    setTimeout(() => {
      setShowConfirmation(false);
      setSubmissionResult(null);
    }, 200)
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = (ev) => {
    const target = ev.target as HTMLDivElement
    const progress = target.scrollTop / (target.scrollHeight - target.clientHeight)
    setScrollProgress(progress)
    if (progress >= 0.95) {
      setScrolledToBottom(true)
    }
  }

  const hasAnswered = currentQuestion === -1 || typeof answers[currentQuestion] === "number";

  const questions = selected?.quiz?.questions ?? [];
  const questionCount = selected?.quiz?.questions?.length ?? 0;
  const isLastQuestion = currentQuestion === questionCount - 1;
  const isTakingQuiz = currentQuestion >= 0

  const dialogTitle = showConfirmation
    ? "Submission Successful!"
    : currentQuestion === -1
    ? selected?.title
    : `Question ${currentQuestion + 1} of ${questions.length}`;

  const dialogProgress = ((currentQuestion + 1) / questions.length) * 100;

  const handlePdfLoaded = useCallback((pdf: PDFDocumentProxy) => {
    if (pdf.numPages === 1) {
      setScrolledToBottom(true)
    }
  }, [])

  return (
    <Drawer open={!!open} onClose={handleClose} anchor="left" PaperProps={{ style: { width: '100%', maxWidth: 500 } }}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      {showConfirmation ? (
        <Divider />
      ) : isTakingQuiz ? (
        <LinearProgress
          variant="determinate"
          value={dialogProgress}
          style={{ flex: '0 0 auto' }}
        />
      ) : <LinearProgress
        variant="determinate"
        value={scrollProgress * 100}
        style={{ flex: '0 0 auto' }}
      />}
      
        {showConfirmation && submissionResult ? (
          <DialogContent>
            <SlideInUp key="result">
              <Box sx={{ position: 'relative', display: 'inline-flex', width: '100%', justifyContent: 'center' }}>
                <CircularProgress
                  variant="determinate"
                  value={((submissionResult.correctAnswers || 0) / questionCount) * 100}
                  size={200}
                  thickness={5}
                  sx={{ mb: 2 }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="h6" component="div" color="text.secondary">
                    {`${(submissionResult.correctAnswers || 0)}/${questionCount}`}
                  </Typography>
                </Box>
              </Box>
            </SlideInUp>
            <SlideInUp key="description" delay={200}>
              <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                {submissionResult.correctAnswers === questionCount
                  ? "Congratulations! You got a perfect score!"
                  : `Great effort! You answered ${(submissionResult.correctAnswers || 0)} out of ${questionCount} questions correctly. Keep learning and you'll do even better next time!`}
              </Typography>
            </SlideInUp>
            {!!submissionResult.amount && <SlideInUp key="reward" delay={400}>
              <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                You've earned {numberFormat(submissionResult.amount, 0)} COIN!
              </Typography>
            </SlideInUp>}
          </DialogContent>
        ) : currentQuestion === -1 ? (
          <DialogContent sx={{ p: 0 }} onScroll={handleScroll}>
            <PdfViewer src={selected?.pdfUrl} onPdfLoaded={handlePdfLoaded} />
          </DialogContent>
        ) : (
          <DialogContent >
            <SlideInUp key={`text_${currentQuestion}`}>
              <Typography variant="h6" gutterBottom>
                {questions[currentQuestion]?.text}
              </Typography>
            </SlideInUp>
            <SlideInUp key={`answers_${currentQuestion}`} delay={200}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={hasAnswered ? answers[currentQuestion] : ""}
                  onChange={handleAnswerChange}
                >
                  {questions[currentQuestion]?.answerOptions.map(
                    (option, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={<Radio />}
                        label={option}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
            </SlideInUp>
          </DialogContent>
        )}
      <Divider />
      <DialogActions>
        {showConfirmation ? null : isTakingQuiz ? (
          <SlideInUp>
            <Button onClick={handleBack}>Back</Button>
          </SlideInUp>
        ) : (
          <SlideInUp>
            <Button onClick={handleClose}>Cancel</Button>
          </SlideInUp>
        )}

        {showConfirmation ? (
          <SlideInUp>
            <Button onClick={handleClose}>Close</Button>
            {selected?.ctaUrl ? <a target="_blank" href={selected?.ctaUrl}>
              <Button variant="contained" color="secondary">
                <strong style={{ color: 'white' }}>Learn More</strong>
              </Button>
            </a> : null}
          </SlideInUp>
        ) : (
          <SlideInUp delay={200}>
            <Button onClick={handleNext} disabled={!hasAnswered || isMutating || (!isTakingQuiz && !scrolledToBottom)} variant="contained" color="secondary">
              <strong style={{ color: 'white' }}>{isLastQuestion ? "Submit" : isTakingQuiz ? "Next" : "Start Quiz"}</strong>
            </Button>
          </SlideInUp>
        )}
      </DialogActions>
    </Drawer>
  );
};

const PdfViewer = ({ src, onPdfLoaded, ...props }: React.ComponentProps<'div'> & { src?: string; onPdfLoaded?: (pdf: PDFDocumentProxy) => void }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!src) return

    loadPdf(src).then(async (pdf) => {
      for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
        if (ref.current) {
          const canvas = document.createElement('canvas')
          canvas.style.width = '100%'
          ref.current.appendChild(canvas)
          await renderPdf(canvas, pdf, pageNumber)
        }
      }
      return pdf
    })
    .then(onPdfLoaded)
    
  }, [src, onPdfLoaded])

  return <div {...props} ref={ref} />
}

// <script src="https://www.google.com/recaptcha/api.js"></script>
let pdfjsLib: typeof PdfJsLib | null = null
const pdfs: Record<string, Promise<PDFDocumentProxy> | undefined> = {}

async function loadPdf (url: string) {
  if (!pdfjsLib) {
    pdfjsLib = await import('pdfjs-dist')
  }

  if (pdfs[url]) return pdfs[url]

  pdfs[url] = pdfjsLib.getDocument(url).promise

  return pdfs[url]
}

async function renderPdf (canvas: HTMLCanvasElement, pdf: PDFDocumentProxy, pageNumber: number) {
  // Fetch the first page
  const page = await pdf.getPage(pageNumber)

  const scale = 1.5;
  const viewport = page.getViewport({scale: scale});

  // Prepare canvas using PDF page dimensions
  var context = canvas.getContext('2d') as CanvasRenderingContext2D;
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  // Render PDF page into canvas context
  var renderTask = page.render({
    canvasContext: context,
    viewport: viewport
  });

  await renderTask.promise
}

