import gql from 'graphql-tag'

const COLLECTIONS_BY_DAY_DAILY_STATS = gql`
query collectionsByDay($start: Int!, $end: Int) {
  collectionsByDay (start: $start, end: $end) {
    coordinates
  }
}`

const COLLECTIONS_DAILY_STATS = gql`
  query CollectionsDailyStats($page: Int, $perPage: Int) {
    myUserInfo {
      mineCount
      bigwinWitnessCount
      rewardedTaskCount
    }
    collectionsDailyStats(pagination: { page: $page, perPage: $perPage }) {
      total
      page
      data {
        id
        yyyymmdd
        uid
        count
        collected
        xyoCollected
        # coordinates
      }
    }
  }
`

export { COLLECTIONS_DAILY_STATS, COLLECTIONS_BY_DAY_DAILY_STATS }
