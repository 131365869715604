import { useQuery } from '@apollo/react-hooks'
import { Box, TablePagination, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '../../../components/Flex'
import times from 'lodash/times'
import { ChangeEventHandler, useMemo, useState } from 'react'

import { CustomList, CustomListHeader, TablePaginationActions, useScrollContext } from '../../../components'
import { ErrorViewer } from '../../../componentsPure'
import { fromYYYYMMDD } from '../../../utils'
import { COLLECTIONS_DAILY_STATS } from './gql'
import CollectionByDay from './Item'
import NoGeoMines from './NoItems'

interface CollectionDailyStatsResult {
  collectionsDailyStats: {
    total: number
    data: { yyyymmdd: number }[]
  }
  myUserInfo: {
    mineCount: number
    bigwinWitnessCount: number
  }
}

const GeominesList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const variables = useMemo(() => ({ page, perPage }), [page, perPage])
  const { data, loading, error } = useQuery<CollectionDailyStatsResult>(COLLECTIONS_DAILY_STATS, {
    variables,
  })
  const remoteRowCount = data?.collectionsDailyStats?.total ?? 0
  const mineCount = data?.myUserInfo?.mineCount ?? 0
  const bigwinWitnessCount = data?.myUserInfo?.bigwinWitnessCount ?? 0
  const rowData = data?.collectionsDailyStats?.data

  const changePage = (p: number) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }

  return (
    <CustomList
      header={
        <CustomListHeader title="Geomines">
          <Box marginLeft={2} textAlign="right">
            {mineCount ? <Typography>Geomines: {num(mineCount)}</Typography> : null}
            {bigwinWitnessCount ? <Typography>Big Win Witness Count: {num(bigwinWitnessCount)}</Typography> : null}
          </Box>
        </CustomListHeader>
      }
    >
      {loading ? (
        times(perPage).map((a, i) => <CollectionByDay key={i} loading={true} yyyymmdd={0} />)
      ) : (rowData || []).length ? (
        (rowData || []).map((collection) => (
          <CollectionByDay
            loading={loading}
            key={collection.yyyymmdd}
            day={fromYYYYMMDD(collection.yyyymmdd)}
            {...collection}
          />
        ))
      ) : error ? (
        <ErrorViewer error={error} />
      ) : (
        <NoGeoMines />
      )}
      {remoteRowCount > 0 ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={remoteRowCount}
          rowsPerPage={variables.perPage}
          page={variables.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      ) : null}
    </CustomList>
  )
}

function num (n: number) {
  return n.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })
}

export default GeominesList
